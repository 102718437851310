@media screen and (max-width: 500px) {
  .title_div {
    width: 100%;
  }

  .title {
    width: 228px !important;
    font-style: normal;
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .btn {
    display: none;
  }

  .btn_mobile {
    display: block !important;
    margin-top: 8px;
    margin-left: 12px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    display: block;
    width: 500px !important;
    left: 144px !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: var(--grey-800);
  }

  .btn {
    display: none;
  }

  .btn_mobile {
    display: block !important;
    margin-top: 8px;
  }
}

.accordion {
  width: 100%;
  border-bottom: 1px solid var(--grey-300);
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin-bottom: 20px;
}

.title {
  width: 500px;
  white-space: normal;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 45px;
  color: var(--grey-800);
  margin-bottom: 12px;
}

.title_div {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: none;
  background: none;
  text-decoration: none;
  text-align: left;
}

.description {
  width: 546px;
  height: 105px;
  left: 142px;
  top: 1705px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey-700);
}

.description_div {
  margin-bottom: 30px;
  margin-left: 4px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 43px;
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 30px;
  width: 25px;
  height: 25px;
}

.btn {
  margin-right: 10px;
  margin-top: 13px;
  width: 20px;
  height: 20px;
}

.btn_mobile {
  display: none;
}

.description_text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey-700);
  width: 546px;
}
