.image {
  position: relative;
  width: 35px;
  height: 39px;
}

.title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: var(--grey-800);
}

.title_div {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 30px;
}

.beta_text {
  width: 31px;
  height: 16px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--blue-500);
}

.beta_div {
  padding: 8px 9px 8px 9px;
  margin-left: 12px;
  background: var(--blue-100);
  border-radius: 4px;
}

.text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-800);
  margin-bottom: 36px;
}

.url {
  margin-right: 14px;
}

.url_icon {
  padding-top: 5px;
}

.url_div {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .image {
    width: 28px;
    height: 31px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
  }

  .title_div {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .text {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 24px;
    width: 80vw;
  }

  .url_div {
    margin-left: 12px;
  }
}
