.navbar_layout {
  width: 100%;
  padding: 100px;
}

.left_side_layout {
  display: flex !important;
  align-items: center;
}

.left_side_layout_account{
  padding-left: 40px !important;
  display: flex !important;
  align-items: center;
}

.text_meu {
  color: var(--indigo-300);
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  cursor: pointer;
}

.text_pluggy {
  color: var(--pink-400);
  font-weight: 600;
  font-size: 1.8rem;
  display: flex;
}

.text_login {
  padding-right: 40px !important;
  color: var(--pink-400) !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}

hr.solid {
  border: 0;
  width: 100%;
  background-color: var(--grey-100);
  height: 1px;
  position: relative;
  margin: 12px 0px;
}

.text_voltar_account {
  font-family: 'Work Sans' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}

@media screen and (max-width: 500px) {
  .paddingHeader {
    padding-bottom: 70px;
  }

  .text_meu {
    color: var(--indigo-300);
    font-weight: 600;
    font-size: 1rem;
    display: flex;
    cursor: pointer;
  }

  .text_pluggy {
    color: var(--pink-400);
    font-weight: 600;
    font-size: 1.2rem;
    display: flex;
  }

  .navbar_row_account {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .navbar_row_lading {
    padding-top: 20px !important;
    padding-bottom: 0px !important;
  }

  .text_login {
    padding-right: 10px !important;
  }

  .left_side_layout_account {
    padding-left: 20px !important;
  }
}